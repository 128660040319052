import React, { Component } from 'react'
import './Perfil.scss'
import './../../main/ultil.scss'
import { Link } from 'react-router-dom'
import Main from '../../components/templates/Main'
import Nav from '../../components/templates/Nav'
import Table from '../../components/Table'
import axios from 'axios'
import { Grid, Button, TextField } from '@mui/material'
import CircularProgress from '@mui/material/CircularProgress';
import Alert from './../../components/templates/Alert'
import ModalAjuda from './../../components/modals/Ajuda'

const initialState = {
  perfil: {},
  perfis: {
    list: []
  },
  perfisFiltrado: {
    list: []
  },
  cabecalhoTabela: [
    { id: 'descricao', numeric: false, disablePadding: true, label: 'Descrição' },
    { id: 'visibilidade', numeric: false, disablePadding: true, label: 'Visibilidade' },
    { id: 'criado', numeric: false, disablePadding: true, label: 'Unidade Responsável' },
    { id: 'status', numeric: false, disablePadding: false, label: 'Ativo' }
  ],
  acoesTabela: [],
  loading: true,
  tipo: "",
  filtro: {
    descricao: "",
    visibilidade: ""
  },
  permissoes: {
    alterar: false,
    inserir: false,
    visualizar: false,
    deletar: false
  },
  idUnidadeTrabalho: '',
  alerta: {
    open: false,
    severity: 'error',
    message: ''
  },
  openModalAjuda: false,
  markdown: ''
}

function searching(term, itemFiltro) {
  return function (x) {
    return x[itemFiltro].toLowerCase().includes(term.toLowerCase()) || !term;
  }
}

export default class Perfil extends Component {
  state = { ...initialState }

  getToken() {
    const USER_TOKEN = localStorage.getItem('token')

    const config = {
      headers: {
        'Authorization': 'Bearer ' + USER_TOKEN,
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }
    }

    return config
  }

  async UNSAFE_componentWillMount() {
    const helpPath = require("./../../help/Listagem.md")

    fetch(helpPath)
      .then(response => {
        return response.text()
      })
      .then(text => {
        this.setState({
          markdown: text
        })
      })

    const perfil = JSON.parse(localStorage.getItem('perfil'))

    let permissoes = perfil.permissoes.filter(param => param.tela.modulo.slug === 'perfil')[0]
    
    let acoesTabela = []

    if (permissoes.alterar) {
      acoesTabela.push('update')
    }

    this.setState({
      acoesTabela,
      permissoes
    })

    const pessoa = JSON.parse(localStorage.getItem('pessoa'))
    const tipo = pessoa.fisica.funcionario.contrato.unidadetrabalho.tipo

    this.setState({
      tipo
    })

    const idUnidadeTrabalho = pessoa.fisica.funcionario.contrato.unidadetrabalho.unin_id

    this.setState({
      idUnidadeTrabalho
    })

    const acesso_atual = JSON.parse(localStorage.getItem('acesso_atual'))

    
    try {
      const dados = {
        unidadesnegocio: acesso_atual.map(acesso => {
          return acesso.id
        })
      }
      const {data } = await axios.post(`${window._env_.REACT_APP_API_URL}/perfis/list`, dados, this.getToken())
    
      const perfis = data.map(perfil => {
        let visibilidade = ''

        if (perfil.visibilidade === 'MANTENEDOR') {
          visibilidade = 'Mantenedor'
        } else if (perfil.visibilidade === 'GRUPOECONOMICO') {
          visibilidade = 'Grupo Econômico'
        } else if (perfil.visibilidade === 'UNIDADE') {
          visibilidade = 'Unidade'
        }else if (perfil.visibilidade === 'REPRESENTANTE') {
          visibilidade = 'Representante'
        }

        let criado = ''

        if (perfil.dono.tipo === 'MANTENEDOR') {
          criado = 'Mantenedor'
        } else if (perfil.dono.tipo === 'GRUPOECONOMICO') {
          criado = 'Grupo Econômico'
        } else if (perfil.dono.tipo === 'UNIDADE') {
          criado = 'Unidade'
        }else if (perfil.dono.tipo === 'REPRESENTANTE') {
          criado = 'Representante'
        }

        return {
          _id: perfil.id,
          descricao: perfil.descricao,
          visibilidade,
          status: perfil.status,
          dono_unin_id: perfil.dono.unin_id,
          criado
        }
      })

      this.setState({
        perfis: {
          list: perfis
        },
        perfisFiltrado: {
          list: perfis
        },
        loading: false
      })

    }  catch (error) {
      console.log(error)
      this.setState({
        alerta: {
          open: true,
          severity: 'error',
          message: error.response ? error.response.data.message : 'Erro Interno'
        }
      })
    }

  }

  updateFiltro(event) {
    this.setState({ term: event.target.value })
    const perfis = this.state.perfis.list
    const filtro = this.state.filtro

    const list = perfis.filter(searching(event.target.value, event.target.name))

    filtro[event.target.name] = event.target.value

    this.setState({
      perfisFiltrado: {
        list
      },
      filtro
    })
  }

  handleCloseAlerta(){
    this.setState({
      alerta: {
        open: false,
        autoHideDuration: 5000,
        severity: this.state.alerta.severity,
        message: ''
      }
    })
  }

  render() {
    const { filtro, perfisFiltrado } = this.state
    return (
      <div className="app-menu-closed" id="app">
        <Main history={this.props.history} openModalAjuda={() => {this.setState({openModalAjuda: true})}}>
          {this.state.loading &&
            <div>
              <CircularProgress />
            </div>
          }
          {!this.state.loading &&
            <div>
              <Grid
                container
                spacing={1}
                direction="row"
                className="borderBottom"
              >
                <Grid item md={9} sm={6} xs={12}>
                  <h1 className="titulo">Perfil de Usuários</h1>
                </Grid>
                <Grid item md={3} sm={6} xs={12}>
                  <Link to="/perfil/cadastro">
                    <Button className="btnCadastrar" variant="contained" color="primary">
                      Cadastrar Perfil
                    </Button>
                  </Link>
                </Grid>
              </Grid>
              <Grid container direction="row" spacing={1} className="mg_top_20">
                <Grid item md={3} xs={12} sm={6}>
                  <TextField className="input" label="Perfil" variant="outlined" size="small" name="descricao" value={filtro.descricao} onChange={(e) => this.updateFiltro(e)} InputLabelProps={{ shrink: true }} placeholder='Pesquisar' />
                </Grid>
                <Grid item md={3} xs={12} sm={6}>
                  <TextField className="input" label="Visibilidade" variant="outlined" size="small" name="visibilidade" value={filtro.visibilidade} onChange={(e) => this.updateFiltro(e)} InputLabelProps={{ shrink: true }} placeholder='Pesquisar' />
                </Grid>
              </Grid>
              <Grid container spacing={1} direction="row" className="mg_top_10">
                <Grid item md={12} xs={12}>
                  <Table
                    urlUpdate="/perfil/cadastro/"
                    urlView="/perfil/cadastro/"
                    headCell={this.state.cabecalhoTabela}
                    rows={perfisFiltrado.list}
                    acoes={this.state.acoesTabela}
                    idUnidadeTrabalho={this.state.idUnidadeTrabalho}
                    tela="Perfil"
                  />
                </Grid>
              </Grid>
            </div>
          }
        </Main>
        <Nav history={this.props.history}/>
        <Alert 
          open={this.state.alerta.open}
          handleClose={e => this.handleCloseAlerta()} 
          severity={this.state.alerta.severity}
          message={this.state.alerta.message} 
        />
        <ModalAjuda 
          open={this.state.openModalAjuda}
          tela={"Listagem de Perfil de Usuário"}
          handleClose={() => {this.setState({openModalAjuda: false})}}
          markdown={this.state.markdown}
        />
      </div>
    )
  }
}