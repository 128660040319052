import React, { useState, useEffect } from 'react';
import './Login.scss';
import './../../main/ultil.scss';
import axios from 'axios';
import Alert from './../../components/templates/Alert';
import moment from 'moment';
import EsqueceuSenha from './../../components/modals/EsqueceuSenha';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import socketClient from './../../components/lib/socket';
import { useNavigate } from 'react-router-dom';
import Tooltip from '@mui/material/Tooltip';
import HelpIcon from '@mui/icons-material/Help';
import ModalAjuda from './../../components/modals/Ajuda'
import verificaForcaDaSenha from './../../services/verificaForcaSenha'
import decrypt from '../../services/decrypt'
import encrypt from '../../services/encrypt'
import Arvore from './Arvore'
import { useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';

const initialState = {
  login: {
    login: '',
    senha: ''
  },
  loading: false,
  modalErro: false,
  erro: {
    titulo: "",
    descricao: ""
  },
  showPassword: false,
  alerta: {
    open: false,
    severity: 'error',
    message: ''
  },
  css: '',
  textoBotao: 'Entrar',
  logado: false,
  dadosUsuario: {
    fisica: {
      nome: ''
    }
  },
  openModalEsqueceuSenha: false,
  dados_esqueceu_a_senha: {
    email: '',
    aonde_foi_enviado: '',
    meio_envio: '',
    usuario: '',
    codigo: '',
    senha: '',
    confirma_senha: '',
    codigo_valido: false,
    usuario_id: ''
  },
  showPasswordConfirmar: false,
  nivelSenha: 'Muito Fraca',
  corSenha: 'red',
  travaBotao: false,
  senhaExpirada: false,
  dadoNovaSenha: {
    usuario_id: '',
    usuario: '',
    senha: '',
    confirma_senha: '',
    login: ''
  },
  showPasswordNovaSenha: false,
  showPasswordConfirmarNovaSenha: false,
  openModalAjuda: false,
  markdown: ""
}

function validacaoEmail(email) {
  const usuario = email.substring(0, email.indexOf("@"));
  const dominio = email.substring(email.indexOf("@") + 1, email.length);

  if (
    usuario.length >= 1 &&
    dominio.length >= 3 &&
    usuario.search("@") === -1 &&
    dominio.search("@") === -1 &&
    usuario.search(" ") === -1 &&
    dominio.search(" ") === -1 &&
    dominio.search(".") !== -1 &&
    dominio.indexOf(".") >= 1 &&
    dominio.lastIndexOf(".") < dominio.length - 1
  ) {
    return true;
  } else {
    return false;
  }
}

const Login = () => {
  const [state, setState] = useState({ ...initialState });
  const navigate = useNavigate();
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));

  useEffect(() => {
    const USER_TOKEN = localStorage.getItem('token');

    if (USER_TOKEN) {
      const pessoa = JSON.parse(localStorage.getItem('pessoa'));

      setState(prevState => ({
        ...prevState,
        logado: true,
        dadosUsuario: pessoa,
      }));
    }

    const helpPath = require("./../../help/login/Login.md")

    fetch(helpPath)
      .then(response => {
        return response.text()
      })
      .then(text => {
        setState((prevState) => ({
          ...prevState,
          markdown: text
        }))
      })
  }, []);

  const loginUsuario = async () => {
    if (!validacaoEmail(state.login.login)) {
      handleOpenDialog({
        titulo: 'Opps',
        descricao: 'Email Invalido!'
      });

      return;
    }

    setState(prevState => ({
      ...prevState,
      textoBotao: 'Autenticando',
      css: 'loading'
    }));

    try {
      const { data } = await axios.post(`${window._env_.REACT_APP_API_URL}/usuarios/login`, state.login)

      let dados_login = JSON.parse(decrypt(data))

      setTimeout(() => {
        if (moment(dados_login.data_expiracao).isBefore(moment())) {
          setState(prevState => ({
            ...prevState,
            senhaExpirada: true,
            loading: false,
            dadoNovaSenha: {
              login: dados_login.login,
              usuario_id: dados_login.usuario_id,
              usuario: dados_login.pessoa.fisica.nome,
              senha: '',
              confirma_senha: '',
            },
            textoBotao: 'Trocar Senha',
            css: ''
          }));
        } else {
          setState(prevState => ({
            ...prevState,
            textoBotao: 'Bem vindo de volta!',
            css: 'ok'
          }));

          setTimeout(() => {
            localStorage.setItem('perfil', JSON.stringify(dados_login.perfil));
            localStorage.setItem('pessoa', JSON.stringify(dados_login.pessoa));
            localStorage.setItem('login', JSON.stringify(dados_login.login));
            localStorage.setItem('unidades_acesso', JSON.stringify(dados_login.unidades_acesso));
            localStorage.setItem('token', encrypt(dados_login.token));
            localStorage.setItem('caixa', JSON.stringify(dados_login.caixa));
            localStorage.setItem('contasPR', JSON.stringify(dados_login.contasPR));

            const usuario = { ...data };
            delete usuario.unidades_acesso;
            delete usuario.pessoa;
            delete usuario.perfil;

            usuario.tempo = moment(new Date()).add(1, 'minutes');
            localStorage.setItem('usuario', JSON.stringify(usuario));

            let ultima_url_login = localStorage.getItem('ultima_url_login');

            // if (ultima_url_login) {
            //   navigate(ultima_url_login);
            // } else {
            //   navigate("/home");
            // }           

            navigate("/home");

            setState(prevState => ({ ...prevState, loading: false }));
          }, 2000);
        }
      }, 2000);
    } catch (error) {
      console.log(error);
      setState(prevState => ({
        ...prevState,
        textoBotao: 'Entrar',
        css: '',
        alerta: {
          open: true,
          severity: 'error',
          message: error.response ? error.response.data.message : 'Erro Interno'
        },
        loading: false
      }));
    }
  };

  const handleOpenDialog = (error) => {
    if (!error) error = {};

    setState(prevState => ({
      ...prevState,
      alerta: {
        open: true,
        severity: 'error',
        message: error.descricao || 'Erro inesperado, informe o suporte'
      }
    }));
  };

  const handleCloseAlerta = () => {
    setState(prevState => ({
      ...prevState,
      alerta: {
        open: false,
        autoHideDuration: 5000,
        severity: state.alerta.severity,
        message: ''
      }
    }));
  };

  const updateField = (event) => {
    const login = { ...state.login };

    login[event.target.name] = event.target.value;

    setState(prevState => ({ ...prevState, login }));
  };

  const handleClickShowPassword = () => {
    setState(prevState => ({
      ...prevState,
      showPassword: !prevState.showPassword
    }));
  };

  const voltar = () => {
    navigate("/home");

    setTimeout(() => {
      socketClient('', {});
    }, 1000);

    setState(prevState => ({ ...prevState, loading: false }));
  };

  const openEsqueceuSenha = () => {
    setState(prevState => ({
      ...prevState,
      openModalEsqueceuSenha: true
    }));
  };

  const handleCloseEsqueceuSenha = () => {
    setState(prevState => ({
      ...prevState,
      openModalEsqueceuSenha: false,
      dados_esqueceu_a_senha: {
        email: '',
        aonde_foi_enviado: '',
        meio_envio: '',
        usuario: '',
        codigo: '',
        senha: '',
        confirma_senha: '',
        codigo_valido: false,
        usuario_id: ''
      },
      travaBotao: false
    }));
  };

  const enviarEmailEsqueceuSenha = async (value) => {
    if (value) {
      try {
        let { dados_esqueceu_a_senha } = state;

        if (dados_esqueceu_a_senha.email === '') {
          setState(prevState => ({
            ...prevState,
            alerta: {
              open: true,
              severity: 'warning',
              message: 'Por favor informar o e-mail.'
            }
          }));
        }

        setState(prevState => ({
          ...prevState,
          travaBotao: true
        }));

        const { data } = await axios.post(`${window._env_.REACT_APP_API_URL}/usuarios/recuperarSenha`, dados_esqueceu_a_senha);

        dados_esqueceu_a_senha.aonde_foi_enviado = data.aonde_foi_enviado;
        dados_esqueceu_a_senha.meio_envio = data.meio_envio;
        dados_esqueceu_a_senha.usuario = data.usuario.pessoa.fisica.nome;
        dados_esqueceu_a_senha.usuario_id = data.usuario.id;

        setState(prevState => ({
          ...prevState,
          dados_esqueceu_a_senha,
          travaBotao: false
        }));
      } catch (error) {
        console.log(error);
        setState(prevState => ({
          ...prevState,
          alerta: {
            open: true,
            severity: 'error',
            message: error.response ? error.response.data.message : 'Erro Interno'
          },
          travaBotao: false
        }));
      }
    } else {
      handleCloseEsqueceuSenha();
    }
  };

  const updateFieldEsqueceuSenha = async (event) => {
    const dados_esqueceu_a_senha = { ...state.dados_esqueceu_a_senha };

    let {name, value} = event.target

    if (name === 'senha') {
      const resp = await verificaForcaDaSenha(value)
      setState(prevState => ({...prevState,
        nivelSenha: resp.forcaSenha,
        corSenha: resp.backgroundColor
      }))
    }

    dados_esqueceu_a_senha[name] = value;

    setState(prevState => ({ ...prevState, dados_esqueceu_a_senha }));
  };

  const updateFieldNovaSenha = async (event) => {
    const dadoNovaSenha = { ...state.dadoNovaSenha };

    let {name, value} = event.target

    if (name === 'senha') {
      const resp = await verificaForcaDaSenha(value)
      setState(prevState => ({...prevState,
        nivelSenha: resp.forcaSenha,
        corSenha: resp.backgroundColor
      }))
    }

    dadoNovaSenha[name] = value;

    setState(prevState => ({ ...prevState, dadoNovaSenha }));
  };

  const confirmarCodigo = async (value) => {
    if(value){
      try {
        let { dados_esqueceu_a_senha } = state;
  
        if (dados_esqueceu_a_senha.codigo === '') {
          setState(prevState => ({
            ...prevState,
            alerta: {
              open: true,
              severity: 'warning',
              message: 'Por favor informar o código enviado ao e-mail.'
            }
          }));
        }
  
        const { data } = await axios.post(`${window._env_.REACT_APP_API_URL}/usuarios/validarCodigoRecuperacao`, dados_esqueceu_a_senha);
  
        if(data.codigo_valido){
          dados_esqueceu_a_senha.codigo_valido = data.codigo_valido;
        }else{
          setState(prevState => ({
            ...prevState,
            alerta: {
              open: true,
              severity: 'error',
              message: 'Código Invalido!'
            }
          }));
        }
  
        setState(prevState => ({ ...prevState, dados_esqueceu_a_senha }));
      } catch (error) {
        console.log(error);
        setState(prevState => ({
          ...prevState,
          alerta: {
            open: true,
            severity: 'error',
            message: error.response ? error.response.data.message : 'Erro Interno'
          }
        }));
      }
    }else{
      handleCloseEsqueceuSenha()
    }
  };

  const trocarSenha = async () => {
    const { dados_esqueceu_a_senha } = state;

    if (dados_esqueceu_a_senha.senha === '') {
      setState(prevState => ({
        ...prevState,
        alerta: {
          open: true,
          severity: 'warning',
          message: 'Por favor informar a senha.'
        }
      }));
      return
    } 
    
    if (dados_esqueceu_a_senha.senha !== dados_esqueceu_a_senha.confirma_senha) {
      setState(prevState => ({
        ...prevState,
        alerta: {
          open: true,
          severity: 'warning',
          message: 'As senhas não conferem.'
        }
      }));
      return
    } 

    try {
      await axios.post(`${window._env_.REACT_APP_API_URL}/usuarios/trocarSenha/${dados_esqueceu_a_senha.usuario_id}`, {novaSenha:dados_esqueceu_a_senha.senha, repetirSenha: dados_esqueceu_a_senha.confirma_senha});

      handleCloseEsqueceuSenha();

      setState(prevState => ({
        ...prevState,
        senhaExpirada: false,
        loading: false
      }));
    } catch (error) {
      console.log(error);
      setState(prevState => ({
        ...prevState,
        alerta: {
          open: true,
          severity: 'error',
          message: error.response ? error.response.data.message : 'Erro Interno'
        }
      }));
    }
  };

  const handleClickShowPasswordConfirmarNovaSenha = () => {
    setState(prevState => ({
      ...prevState,
      showPasswordConfirmarNovaSenha: !prevState.showPasswordConfirmarNovaSenha
    }));
  };

  const handleClickShowPasswordNovaSenha = () => {
    setState(prevState => ({
      ...prevState,
      showPasswordNovaSenha: !prevState.showPasswordNovaSenha
    }));
  };

  const trocarNovaSenha = async () => {
    try {
      let {dadoNovaSenha, nivelSenha} = state

      if(dadoNovaSenha.senha === ''){
        setState(prevState => ({
          ...prevState,
          alerta: {
            open: true,
            severity: 'warning',
            message: 'Por favor informar a senha.'
          }
        }));
        return
      }

      if(dadoNovaSenha.confirma_senha === ''){
        setState(prevState => ({
          ...prevState,
          alerta: {
            open: true,
            severity: 'warning',
            message: 'Por favor informar a confirmação da senha.'
          }
        }));
        return
      }

      if(dadoNovaSenha.senha !== dadoNovaSenha.confirma_senha){
        setState(prevState => ({
          ...prevState,
          alerta: {
            open: true,
            severity: 'warning',
            message: 'As senhas não conferem.'
          }
        }));
        return
      }

      if(nivelSenha === 'Muito Fraca' || nivelSenha === 'Fraca' || nivelSenha === 'Média'){
        setState(prevState => ({
          ...prevState,
          alerta: {
            open: true,
            severity: 'warning',
            message: 'Por favor informar uma senha mais forte.'
          }
        }));
        return
      }

      setState(prevState => ({
        ...prevState,
        textoBotao: 'Autenticando',
        css: 'loading'
      }));

      await axios.post(`${window._env_.REACT_APP_API_URL}/usuarios/trocarSenha/${dadoNovaSenha.usuario_id}`, {novaSenha:dadoNovaSenha.senha, repetirSenha: dadoNovaSenha.confirma_senha})

      const { data } = await axios.post(`${window._env_.REACT_APP_API_URL}/usuarios/login`, {login: dadoNovaSenha.login, senha: dadoNovaSenha.senha})

      let dados_login = JSON.parse(decrypt(data))

      setTimeout(() => {
        setState(prevState => ({
          ...prevState,
          textoBotao: 'Bem vindo de volta!',
          css: 'ok'
        }));

        setTimeout(() => {
          localStorage.setItem('perfil', JSON.stringify(dados_login.perfil))
          localStorage.setItem('pessoa', JSON.stringify(dados_login.pessoa))
          localStorage.setItem('login', JSON.stringify(dados_login.login))
          localStorage.setItem('unidades_acesso', JSON.stringify(dados_login.unidades_acesso))
          localStorage.setItem('token', encrypt(dados_login.token))
          localStorage.setItem('caixa', JSON.stringify(dados_login.caixa))
          localStorage.setItem('contasPR', JSON.stringify(dados_login.contasPR))
    
          const usuario = { ...dados_login }
          delete usuario.unidades_acesso
          delete usuario.pessoa
          delete usuario.perfil

          // socketClient({acao: 'drop-user', values: usuario})
    
          usuario.tempo = moment(new Date()).add(1 , 'minutes')
          localStorage.setItem('usuario', JSON.stringify(usuario))
    
          let ultima_url_login = localStorage.getItem('ultima_url_login')

          if(ultima_url_login){
            navigate(ultima_url_login);
          }else{
            navigate("/home");
          }

          setTimeout(() => {
            socketClient('',{})
          }, 1000);

          setState(prevState => ({
            ...prevState,
            loading: false
          }));
          return;
        }, 2000);

      }, 2000);
    } catch (error) {
      console.log(error)
      setState(prevState => ({
        ...prevState,
        alerta: {
          open: true,
          severity: 'error',
          message: error.response ? error.response.data.message : 'Erro Interno'
        },
        loading: false,
        textoBotao: 'Trocar Senha',
        css: ''
      }));
    }
  }

  const handleClickShowPasswordConfirmar = () => {
    setState(prevState => ({
      ...prevState,
      showPasswordConfirmar: !state.showPasswordConfirmar
    }));
  }

  const { login, css, textoBotao, showPassword, logado, dadosUsuario, senhaExpirada, dadoNovaSenha, showPasswordNovaSenha, showPasswordConfirmarNovaSenha } = state

  return (
    <React.Fragment>
      {logado &&
        <div className="wrapper">
          <div className={`login`} style={{textAlign: 'center'}}>
            <img className='logo_mais_financeiro_login' src="./images/BRAND_MAISFINANCEIRO.png" alt="Logo Mais Financeiro" />
            <h4 style={{textTransform: 'uppercase'}}>Você já possui uma sessão ativa neste terminal!</h4>
            <p style={{textTransform: 'uppercase', marginTop: '10px', marginBottom: '10px'}}>Usuário: {dadosUsuario.fisica.nome}</p>
            <button onClick={() => voltar()}>
              <i className="spinner"></i>
              <span style={{textTransform: 'uppercase'}} className="state">{textoBotao}</span>
            </button>
          </div>
        </div>
      }
      {!logado && 
        <div className="wrapper">
          <div data-cy="card-login" className={`login ${css}`}>
            {!senhaExpirada &&
              <React.Fragment>
                <img data-cy="logo-mais-financeiro" className='logo_mais_financeiro_login' src="./images/BRAND_MAISFINANCEIRO.png" alt="Logo Mais Financeiro" />
                <input data-cy="login" type="text" placeholder="Email" autofocus name="login" value={login.login} onChange={(evt) => updateField(evt)}/>
                <i className="fa fa-user"></i>
                <input data-cy="senha" type={showPassword ? "text" : "password"} placeholder="Senha" name="senha" value={login.senha} onChange={(evt) => updateField(evt)}/>
                <i className="fa fa-key"></i>

                {!showPassword &&
                  <VisibilityIcon data-cy="button-visibility-on" className='visibilityPassword' onClick={e => handleClickShowPassword()} style={{display: css === 'loading' ? 'none': 'block'}}/>
                }
                {showPassword &&
                  <VisibilityOffIcon data-cy="button-visibility-off" className='visibilityPassword' onClick={e => handleClickShowPassword()} style={{display: css === 'loading' ? 'none': 'block'}}/>
                }

                {/* <a href="#">Forgot your password?</a> */}
                <button data-cy="button-login" onClick={() => loginUsuario()}>
                  <i class="spinner"></i>
                  <span class="state">{textoBotao}</span>
                </button>
                
              </React.Fragment>
            }
            {senhaExpirada &&
              <React.Fragment>
                <img data-cy="logo-mais-financeiro" className='logo_mais_financeiro_login' src="./images/BRAND_MAISFINANCEIRO.png" alt="Logo Mais Financeiro" />
                <p className="title">Senha Expirada!</p>
                <p className="title">Olá {dadoNovaSenha.usuario}, a sua senha expirou por favor informar uma nova senha.</p>
                <input type={showPasswordNovaSenha ? "text" : "password"} placeholder="Nova Senha" name="senha" value={dadoNovaSenha.senha} onChange={(evt) => updateFieldNovaSenha(evt)}/>
                <i className="fa fa-key"></i>
                {!showPasswordNovaSenha &&
                  <VisibilityIcon className='visibilityPassword' onClick={e => handleClickShowPasswordNovaSenha()} style={{display: css === 'loading' ? 'none': 'block', top: 325}} />
                }
                {showPasswordNovaSenha &&
                  <VisibilityOffIcon className='visibilityPassword' onClick={e => handleClickShowPasswordNovaSenha()} style={{display: css === 'loading' ? 'none': 'block', top: 325}}/>
                }

                <input type={showPasswordConfirmarNovaSenha ? "text" : "password"} placeholder="Confirmar Nova Senha" name="confirma_senha" value={dadoNovaSenha.confirma_senha} onChange={(evt) => updateFieldNovaSenha(evt)}/>
                <i className="fa fa-key"></i>
                {!showPasswordConfirmarNovaSenha &&
                  <VisibilityIcon className='visibilityPassword' onClick={e => handleClickShowPasswordConfirmarNovaSenha()} style={{display: css === 'loading' ? 'none': 'block', top: 395}}/>
                }
                {showPasswordConfirmarNovaSenha &&
                  <VisibilityOffIcon className='visibilityPassword' onClick={e => handleClickShowPasswordConfirmarNovaSenha()} style={{display: css === 'loading' ? 'none': 'block', top: 395}}/>
                }
                <span>Nivel Senha: <b style={{ color: state.corSenha }}>{state.nivelSenha}</b> </span>
                <br/><span>Essa nova senha irá expirar em {moment().add(3, 'months').format('DD/MM/YYYY')}</span>

                <button onClick={() => trocarNovaSenha()}>
                  <i class="spinner"></i>
                  <span class="state">{textoBotao}</span>
                </button>
                
              </React.Fragment>
            }
          </div>
          {!senhaExpirada &&
            <span data-cy="esqueceu-senha" className='esqueceu_senha' onClick={() => openEsqueceuSenha()}>Esqueceu sua senha?</span>
          }
          <footer><a target="blank" href="http://clicksistemas.com.br/">Click Sistemas</a></footer>
        </div>
      }

      <Alert 
        open={state.alerta.open}
        handleClose={e => handleCloseAlerta()} 
        severity={state.alerta.severity}
        message={state.alerta.message} 
      />

      <EsqueceuSenha 
        open={state.openModalEsqueceuSenha}
        handleClose={e => handleCloseEsqueceuSenha()} 
        dados={state.dados_esqueceu_a_senha}
        confirmar={(e) => enviarEmailEsqueceuSenha(e)}
        updateField={(e) => updateFieldEsqueceuSenha(e)}
        confirmarCodigo={(e) => confirmarCodigo(e)}
        handleClickShowPassword={(e) => handleClickShowPassword(e)}
        showPassword={state.showPassword}
        handleClickShowPasswordConfirmar={() => handleClickShowPasswordConfirmar()}
        showPasswordConfirmar={state.showPasswordConfirmar}
        trocarSenha={(e) => trocarSenha(e)}
        nivelSenha={state.nivelSenha}
        corSenha={state.corSenha}
        travaBotao={state.travaBotao}
      />
    </React.Fragment>
  );
};

export default Login;
