import React, { useState, useEffect } from 'react';
import './Compras.scss'
import './../../main/ultil.scss'
import Main from '../../components/templates/Main'
import Nav from '../../components/templates/Nav'
import { Grid, Button } from '@mui/material'
import FormCompra from '../../components/forms/FormCompra'
import SaveIcon from '@mui/icons-material/Save';
import Loading from './../../components/templates/Loading'
import axios from 'axios'
import Alert from './../../components/templates/Alert'
import moment from 'moment'
import real from './../../services/real'
import moeda from './../../services/moeda'
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ModalAjuda from './../../components/modals/Ajuda'
import { useParams, useNavigate } from 'react-router-dom';
import { format } from 'cnpj'
import decrypt from '../../services/decrypt'

const cpf = require('node-cpf-cnpj');

const initialState = {
  compra: {
    id: '',
    data_pedido: '',
    observacoes: '',
    unidade_id: '',
    numero:  '',
    servicos: []
  },
  servico: {
    item_id: '',
    grupo_id: '',
    sub_grupo_id: '',
    produto_id: '',
    quantidade: 1,
    data_execucao: '',
    documento: '',
    valor_unit: real(0),
    valor_total_pedido: real(0),
    desconto: real(0),
    nome: '',
    index: '',
    tipo: ''
  },
  loading: true,
  mensagemLoading: 'Carregando Compras...',
  permissoes: {
    alterar: false,
    inserir: false,
    visualizar: false,
    deletar: false
  },
  alerta: {
    open: false,
    severity: 'error',
    message: ''
  },
  pessoas:{
    list: []
  },
  cliente: {
    pessoa_id: '',
    nome: ''
  },
  produtos: {
    list: []
  },
  updateServico: false,
  servico_selecionado: {
    id: '',
    nome: '',
    unidademedida: '',
    tipo: '',
    valor: real(0),
    valor_total_minimo: real(0),
    valor_total_maximo: real(0)
  },
  conta: {
    id: '',
    valor_total: real(0),
    valor_total_minimo: 0,
    valor_total_maximo: 0,
    qtd_parcelas: 1,
    parcelas: [
      {
        id: '',
        baixado: "",
        multajuros: real(0),
        datavencimento: moment().format('YYYY-MM-DD'),
        datavencimento_orig: moment().format('YYYY-MM-DD'),
        valorparcela: real(0),
        formapagto_id: "",
        contfin_id: "",
        documento: "",
        habilita: "",
        num: 1,
        parcela_editada: false,
        index: 0,
        trava: false,
        vouchersCreditoSelecionados: [],
        vouchersCreditoSelecionadosString: ''
      }
    ]
  },
  dadosTelaVoucherCredito: {
    totalConta: real(0),
    totalVouchers: real(0),
    diferenca: real(0),
    parcela_index: 0
  },
  vouchersCreditoCliente: [],
  vouchersCreditoSelecionados: [],
  vouchersCreditoSelecionadosString: '',
  formasPagamento:{
    list: []
  },
  contasFinanceira: {
    list: []
  },
  logado: {
    caixa: false
  },
  travarUnidade: false,
  filtro_produtos: 'PRODUTO',
  unidades: {
    list: []
  },
  openModalAjuda: false,
  markdown: '',
  grupoProdutos: {
    list: []
  },
  subGrupoProdutos: {
    list: []
  }
}

function somarValores(array) {
  var soma = 0;
  for (var i = 0; i < array.length; i++) {
    soma += array[i];
  }
  return soma
}

const CadastroCompras = () => {
  const [state, setState] = useState({ ...initialState });
  const navigate = useNavigate();
  const { compraId } = useParams();

  const getToken = () => {
    const USER_TOKEN = localStorage.getItem('token')

    const config = {
      headers: {
        'Authorization': 'Bearer ' + USER_TOKEN,
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }
    }

    return config
  }

  const consultarFormaPagto = async () => {
    try {
      const { data: formasPagamento } = await axios.get(`${window._env_.REACT_APP_API_URL}/formapagto`, getToken())

      setState(prevState => ({...prevState,
        formasPagamento: {
          list: formasPagamento.filter(param => param.ativo === true && param.slug !== 'voucher_produto')
        }
      }))

    } catch (error) {
      console.log(error)
      setState(prevState => ({...prevState,
        alerta: {
          open: true,
          severity: 'error',
          message: error.response ? error.response.data.message : 'Erro Interno'
        }
      }))
    }
  }

  const consultarContasFinanceira = async () => {
    try {
      const acesso_atual = JSON.parse(localStorage.getItem('acesso_atual'))

      const { data: contasFinanceira } = await axios.get(`${window._env_.REACT_APP_API_URL}/contaFinanceira/listByUnidadeAll/${acesso_atual[0].id}`, getToken())

      if(state.logado.caixa){
        const ultimo = JSON.parse(localStorage.getItem('ultimoCaixa'))

        setState(prevState => ({...prevState,
          contasFinanceira: {
            list: contasFinanceira.filter(param => param.id === ultimo['conta.id'])
          }
        }))

      }else{
        setState(prevState => ({...prevState,
          contasFinanceira: {
            list: contasFinanceira
          }
        }))
      }
    

    } catch (error) {
      console.log(error)
      setState(prevState => ({...prevState,
        alerta: {
          open: true,
          severity: 'error',
          message: error.response ? error.response.data.message : 'Erro Interno'
        }
      }))
    }
  }

  const consultaServicos = async (unidade_id, sub_grupo_id) => {
    try {
      const dados = {
        unidadesnegocio: [unidade_id],
        sub_grupo_id
      }

      const resp = await axios.post(`${window._env_.REACT_APP_API_URL}/produtos/list`, dados, getToken())

      const produtos = []

      for (const produto of resp.data) {
        produtos.push({
          id: produto.id,
          nome: produto.descricao,
          codigo: produto.codigo,
          unidademedida: produto.unidademedida,
          tipo: produto.tipo,
          valor: null,
        })
      }

      setState(prevState => ({...prevState,
        produtos: {
          list: produtos
        }
      }))

      setState(prevState => ({...prevState,
        alerta: {
          open: false,
          severity: 'info',
          message: ''
        }
      }))

      return produtos

    } catch (error) {
      console.log(error)
      setState(prevState => ({...prevState,
        alerta: {
          open: true,
          severity: 'error',
          message: error.response ? error.response.data.message : 'Erro Interno'
        }
      }))
    }
  }

  const updateField = async (event) => {
    const {compra} = state

    let name = event.target.name
    let value = event.target.value

    if(name === 'unidade_id'){
      await consultarGrupos(parseInt(value))
      await consultarSubGrupos(parseInt(value))
    }

    compra[name] = value
    setState(prevState => ({...prevState, compra }))
  }

  const backPage = (timeout = 2000) => {
    setTimeout(() => {
      navigate("/compras");
    }, timeout)
  }

  const handleCloseAlerta = () => {
    setState(prevState => ({...prevState,
      alerta: {
        open: false,
        autoHideDuration: 5000,
        severity: state.alerta.severity,
        message: ''
      }
    }))
  }

  const updateFieldPessoaFiltro = (event, value) => {
    if(!value){
      setState(prevState => ({...prevState,
        cliente: {
          pessoa_id: '',
          nome: ''
        },
      }))

      return false
    } 

    setState(prevState => ({...prevState,
      cliente: value
    }))
  }

  const updateFiltroPessoa = async (event) => {
    if(event.target.value.length >= 3){
      await consultarPessoa(event.target.value)
    }else if(event.target.value.length < 3){
      setState(prevState => ({...prevState,
        pessoas: {
          list: []
        }
      }))
    }
  }

  const consultarPessoa = async (nome) => {
    try {

      const {compra} = state

      const dados = {
        unidadesnegocio: [compra.unidade_id]
      }
  
      let filtroPessoa = {
        unidadesnegocio: dados.unidadesnegocio,
        ativo: 'Sim',
        nome: nome
      }

      let { data } = await axios.post(`${window._env_.REACT_APP_API_URL}/pessoa/listSearch`, filtroPessoa, getToken())

      data = JSON.parse(decrypt(data))

      const pessoas = data.map(pessoa => {
        return {
          pessoa_id: pessoa.id,
          nome: pessoa.nome + (pessoa.sobrenome ? ` ${pessoa.sobrenome}` : ''),
        }
      })

      setState(prevState => ({...prevState,
        pessoas: {
          list: pessoas.sort((a, b) => (a.nome > b.nome) ? 1 : (b.nome > a.nome) ? -1 : 0)
        }
      }))

    } catch (error) {
      console.log(error)
      setState(prevState => ({...prevState,
        alerta: {
          open: true,
          severity: 'error',
          message: error.response ? error.response.data.message : 'Erro Interno'
        }
      }))
    }
  }

  const updateFieldServicoAutoComplete = async (event, value) => {
    let {servico_selecionado, servico} = state

    servico.produto_id = ''
    servico.quantidade = 1
    servico.valor_unit = real(0)
    servico.valor_total_pedido = real(0) 

    if(!value){
      setState(prevState => ({...prevState,
        servico,
        servico_selecionado: {
          id: '',
          nome: '',
          unidademedida: '',
          duracao: '',
          tipo: '',
          valor: '',
          valor_total_minimo: '',
          valor_total_maximo: ''
        }
      }))

    }else{
      let resp = await consultarTabelaPreco(parseInt(value.id),moment().format('YYYY-MM-DD'))

      servico_selecionado = value

      if(!resp.preco_produto){
        // setState(prevState => ({...prevState,
        //   alerta: {
        //     open: true,
        //     severity: 'warning',
        //     message: 'Não foi encontrada nenhuma tabela de preço para esse produto/serviço.'
        //   }
        // }))

        servico_selecionado.valor = real(0)
        servico_selecionado.valor_total_minimo = real(0)
        servico_selecionado.valor_total_maximo = real(0)

        servico.valor_unit = real(0)
        servico.valor_total_pedido = real(0)
      }else{
        servico_selecionado.valor = real(resp.preco_produto.valor_maximo)
        servico_selecionado.valor_total_minimo = real(resp.preco_produto.valor_minimo)
        servico_selecionado.valor_total_maximo = real(resp.preco_produto.valor_maximo)
        
        servico.valor_unit = real(resp.preco_produto.valor_maximo)
        servico.valor_total_pedido = real(resp.preco_produto.valor_maximo * servico.quantidade)
      }

      servico.produto_id = value.id

      setState(prevState => ({...prevState,
        servico_selecionado,
        servico
      }))
    }

  }

  const consultarTabelaPreco = async (produto_id, dia) => {
    try {
      
      const acesso_atual = JSON.parse(localStorage.getItem('acesso_atual'))

      let dados = {
        data: dia
      }

      const {data} = await axios.post(`${window._env_.REACT_APP_API_URL}/tabelaPreco/produtoData/${produto_id}/${acesso_atual[0].id}`, dados, getToken())

      setState(prevState => ({...prevState,
        tabela_preco: data
      }))

      return data

    } catch (error) {
      console.log(error)
      setState(prevState => ({...prevState,
        alerta: {
          open: true,
          severity: 'error',
          message: error.response ? error.response.data.message : 'Erro Interno'
        }
      }))
    }
  }

  const addServico = () => {
    let {servico, servico_selecionado, compra} = state
  
    if(servico.produto_id === ''){
      setState(prevState => ({...prevState,
        alerta: {
          open: true,
          severity: 'warning',
          message: 'Por favor selecionar o serviço.'
        }
      }))
      return false
    }

    if(servico.quantidade === ''){
      setState(prevState => ({...prevState,
        alerta: {
          open: true,
          severity: 'warning',
          message: 'Por favor selecionar a quantidade.'
        }
      }))
      return false
    }

    if(servico.valor_unit === ''){
      setState(prevState => ({...prevState,
        alerta: {
          open: true,
          severity: 'warning',
          message: 'Por favor informar o valor unitário.'
        }
      }))
      return false
    }

    if(servico.index === ''){
      servico.index = compra.servicos.length
      servico.nome = servico_selecionado.nome
      servico.tipo = servico_selecionado.tipo

      compra.servicos.push({
        ...servico,
      })
    }else{
      compra.servicos[servico.index] = {...servico}
    }

    setState(prevState => ({...prevState,
      compra,
      servico: {
        item_id: '',
        grupo_id: '',
        sub_grupo_id: '',
        produto_id: '',
        quantidade: 1,
        data_execucao:'',
        valor_unit: real(0),
        valor_total_pedido: real(0),
        desconto: real(0),
        nome: '',
        index: '',
        tipo: ''
      },
      servico_selecionado: {
        id: '',
        nome: '',
        duracao: '',
        tipo: '',
        valor: real(0),
        valor_total_minimo: real(0),
        valor_total_maximo: real(0)
      },
      updateServico: false
    }))

    atualizaConta()
  }

  const updateFieldServico = async (event) => {
    const {servico, compra} = state

    let name = event.target.name
    let value = event.target.value
    servico[name] = value

    if(name === 'quantidade' && parseInt(value) === 0){
      servico.quantidade = 1
    }

    if(name === 'quantidade' || name === 'valor_unit' || name === 'desconto'){
      let valor_unitario = servico.valor_unit === "" ? 0 :parseFloat(servico.valor_unit.replaceAll(".", "").replace(",", "."))
      let valor_desconto = servico.desconto === "" ? 0 :parseFloat(servico.desconto.replaceAll(".", "").replace(",", "."))

      servico.valor_total_pedido = real( (valor_unitario * parseInt(servico.quantidade)) + (valor_desconto * parseInt(servico.quantidade)) )
    }

    if(name === 'sub_grupo_id'){
      await consultaServicos(compra.unidade_id, parseInt(value))
    }
    
    setState(prevState => ({...prevState, servico }))
  }

  const editarItemServico = async (index) => {
    let {compra} = state

    let produtos = await consultaServicos(compra.unidade_id, parseInt(compra.servicos[index].sub_grupo_id))

    let index_produto = produtos.findIndex(param => param.id === compra.servicos[index].produto_id)
    let servico_selecionado = produtos[index_produto]

    let resp = await consultarTabelaPreco(parseInt(servico_selecionado.id),moment().format('YYYY-MM-DD'))
  
    if(!resp.preco_produto){
      servico_selecionado.valor = real(0)
      servico_selecionado.valor_total_minimo = real(0)
      servico_selecionado.valor_total_maximo = real(0)
    }else{
      servico_selecionado.valor = real(resp.preco_produto.valor_maximo)
      servico_selecionado.valor_total_minimo = real(resp.preco_produto.valor_minimo)
      servico_selecionado.valor_total_maximo = real(resp.preco_produto.valor_maximo)
    }

    setState(prevState => ({...prevState,
      servico: compra.servicos[index],
      servico_selecionado,
      updateServico: true
    }))
  }

  const removerItemServico = (index) => {
    let {compra} = state

    let itens = compra.servicos

    itens.splice(index, 1)

    compra.servicos = itens
    
    setState(prevState => ({...prevState,
      compra
    }))

    atualizaConta()
  }

  const updateFieldConta = (event) => {
    const {conta} = state
    conta[event.target.name] = event.target.value
    setState(prevState => ({...prevState,conta}))
  }

  const mudarParcelas = async (event) => {
    const {conta, cliente} = state
    
    let array = []
    const valorTotal = parseFloat(conta.valor_total.replaceAll(".", "").replace(",", "."))

    let qtdParcelasInfo = 1

    if(parseInt(event.target.value) > 0){
      qtdParcelasInfo = parseInt(event.target.value)
    }

    const dataHoje = new Date(moment(conta.parcelas[0].datavencimento).format('YYYY-MM-DD HH:mm:ss'))

    if(!conta.parcelas[0].trava){
      let valorDividido = valorTotal / qtdParcelasInfo
      valorDividido = parseFloat(valorDividido.toFixed(2))
  
      let sobra = valorTotal - (valorDividido * qtdParcelasInfo)
  
      sobra = parseFloat(sobra.toFixed(2))
  
      for (let i = 0; i < qtdParcelasInfo; i++) {
        let mesAcrecentado = new Date(moment(conta.parcelas[0].datavencimento).format('YYYY-MM-DD HH:mm:ss'))
        mesAcrecentado.setMonth(dataHoje.getMonth() + (i))
  
        let parcela = {
          id: conta.parcelas[i] ? conta.parcelas[i].id : '',
          baixado: "",
          datavencimento: moment(mesAcrecentado).format('YYYY-MM-DD'),
          valorparcela: real(valorDividido),
          multajuros: real(0),
          formapagto_id: "",
          documento: "",
          num: i + 1,
          parcela_editada: false,
          index: i,
          trava: false,
          vouchersCreditoSelecionados: [],
          vouchersCreditoSelecionadosString: ''
        }

        if(parcela.id === '' || !parcela.id){
          delete parcela.id
        }

        array.push({
          ...parcela
        })
      }
  
      if (sobra !== 0) {
        array = ajustarArray(qtdParcelasInfo, array, valorDividido, sobra)
      }

      conta.numparcela = qtdParcelasInfo
      conta.qtd_parcelas = qtdParcelasInfo
    }

    if(conta.parcelas[0].trava){
      array.push({...conta.parcelas[0]})

      let valor_restante = valorTotal -  parseFloat(conta.parcelas[0].valorparcela.replaceAll(".", "").replace(",", "."))
      qtdParcelasInfo -= 1

      let valorDividido = valor_restante / qtdParcelasInfo
      valorDividido = parseFloat(valorDividido.toFixed(2))
      
      let sobra = valor_restante - (valorDividido * qtdParcelasInfo)

      for (let i = 0; i < qtdParcelasInfo; i++) {
        array.push({
          baixado: "",
          datavencimento: moment().format('YYYY-MM-DD'),
          valorparcela: real(valorDividido),
          multajuros: real(0),
          formapagto_id: "",
          documento: "",
          num: i + 2,
          parcela_editada: false,
          index: i + 1,
          trava: false,
          vouchersCreditoSelecionados: [],
          vouchersCreditoSelecionadosString: ''
        })

        if (sobra !== 0) {
          array = ajustarArray(qtdParcelasInfo, array, valorDividido, sobra)
        }
      }

      conta.numparcela = qtdParcelasInfo + 1
      conta.qtd_parcelas = qtdParcelasInfo + 1

    }
    
    array[0].multajuros = conta.parcelas[0].multajuros

    conta.parcelas = array

    await buscaVouchersCredito(cliente.pessoa_id, 'CREDITO')

    setState(prevState => ({...prevState, 
      conta,
      dadosTelaVoucherCredito: {
        totalConta: real(0),
        totalVouchers: real(0),
        diferenca: real(0),
        parcela_index: 0
      }
    }))
  }

  const buscaVouchersCredito = async (pessoa_id, tipo) => {
    try {
      
      const {data} = await axios.get(`${window._env_.REACT_APP_API_URL}/voucher/buscarVoucherPessoa/${pessoa_id}/${tipo}`, getToken())

      if(data.length > 0){
        setState(prevState => ({...prevState,
          vouchersCreditoCliente: data.map(value => {
            return {
              ...value,
              saldo_form: 'R$ ' + real(value.saldo),
              novo_saldo: value.saldo,
              novo_saldo_form: 'R$ ' + real(value.saldo),
            }
          }),
          vouchersCreditoSelecionados: []
        }))
      }

      return data
    
    }catch (error) {
      console.log(error)
      setState(prevState => ({...prevState,
        alerta: {
          open: true,
          severity: 'error',
          message: error.response ? error.response.data.message : 'Erro Interno'
        }
      }))
    }
  }

  const ajustarArray = (qtdParcela, array, valorDividido, sobra) => {
    const qtdSobra = parseInt(sobra.toString().replace('-', '').replace('0.0', ''))

    const arrayFalse = array.filter(param => param.parcela_editada === false)
    const arrayTrue = array.filter(param => param.parcela_editada === true)

    for (let i = 0; i < qtdSobra; i++) {
      arrayFalse[arrayFalse.length - (i + 1)].valorparcela = real((valorDividido + ((sobra) / qtdSobra)).toFixed(2))
    }

    for (let i = 0; i < arrayTrue.length; i++) {
      arrayFalse.push(arrayTrue[i])
    }

    return arrayFalse
  }

  const recalcularParcela = (event, index) => {
    const conta = { ...state.conta }

    const parcelaEditada = conta.parcelas[index]

    if (parcelaEditada.valorparcela !== event.target.value) {

      const valorTotal = parseFloat(conta.valor_total.replaceAll(".", "").replace(",", "."))

      let qtdParcelasEditado = state.qtdParcelasEditado

      if (!parcelaEditada.parcela_editada) {
        conta.parcelas[index].parcela_editada = true
        qtdParcelasEditado = qtdParcelasEditado + 1

        setState(prevState => ({...prevState,
          qtdParcelasEditado
        }))
      }

      conta.parcelas[index][event.target.name] = event.target.value

      const parcelasEditada = conta.parcelas.filter(param => param.parcela_editada)
      const somaParcelasEditada = somarValores(parcelasEditada.map(parcela => { return parseFloat(parcela.valorparcela.replaceAll(".", "").replace(",", ".")) }))
      
      const diferenca = valorTotal - somaParcelasEditada
      
      const qtdParcelasNaoEditada = conta.qtd_parcelas - qtdParcelasEditado
      
      let valorDividido = parseFloat(diferenca / qtdParcelasNaoEditada).toFixed(2)
      valorDividido = parseFloat(valorDividido)

      let sobra = diferenca - (valorDividido * qtdParcelasNaoEditada)

      sobra = parseFloat(sobra.toFixed(2))

      for (let i = 0; i < conta.qtd_parcelas; i++) {
        if (!conta.parcelas[i].parcela_editada) {
          conta.parcelas[i].valorparcela = moeda(valorDividido)
        }
      }

      if (sobra !== 0) {
        conta.parcelas = ajustarArray(conta.qtd_parcelas, conta.parcelas, valorDividido, sobra)
      }

      setState(prevState => ({...prevState, conta }))
    }
  }

  const updateFieldParcela = async (event, index) => {
    const {conta, formasPagamento, dadosTelaVoucherCredito, vouchersCreditoCliente, cliente} = state

    let value = event.target.value
    let name = event.target.name

    if(name === 'datavencimento'){
      conta.parcelas[index][name] = value
    }else{
      conta.parcelas[index][name] = value
    }

    if(name === 'formapagto_id'){
      if(value !== ''){
        const formaPagtoSelecionada = formasPagamento.list.filter(param => param.id === parseInt(value))[0]
  
        if(formaPagtoSelecionada.slug === "voucher"){
        
          let valorparcela = parseFloat(conta.parcelas[index].valorparcela.replaceAll(".", "").replace(",", "."))
          let multajuros = parseFloat(conta.parcelas[index].multajuros.replaceAll(".", "").replace(",", "."))

          let totalConta = valorparcela + multajuros

          dadosTelaVoucherCredito.totalConta = real(totalConta)
          dadosTelaVoucherCredito.totalVouchers = real(0) 
          dadosTelaVoucherCredito.diferenca = real(totalConta)
          dadosTelaVoucherCredito.parcela_index = index

          if(vouchersCreditoCliente.length === 0){
            setState(prevState => ({...prevState,
              alerta: {
                open: true,
                severity: 'info',
                message: 'Buscando Vouchers'
              }
            }))
            let vouchers = await buscaVouchersCredito(cliente.pessoa_id, 'CREDITO')

            if(vouchers.length > 0){
              setState(prevState => ({...prevState,
                openModalSelecionarVoucherCredito: true,
                vouchersCreditoSelecionados: [],
                alerta: {
                  open: false,
                  severity: 'info',
                  message: ''
                }
              }))
            }else{
              let {conta} = state
      
              conta.parcelas = [
                {
                  id: '',
                  baixado: false,
                  datavencimento: moment(new Date()).format('YYYY-MM-DD'),
                  datavencimento_orig: moment(new Date()).format('YYYY-MM-DD'),
                  valorparcela: conta.valortotal,
                  multajuros: real(0),
                  formapagto_id: "",
                  contfin_id: "",
                  documento: "",
                  habilita: "",
                  num: 1,
                  parcela_editada: false,
                  index: 0,
                  trava: false,
                  vouchersCreditoSelecionados: [],
                  vouchersCreditoSelecionadosString: '',
                }
              ]
              
              setState(prevState => ({...prevState,
                conta,
                alerta: {
                  open: true,
                  severity: 'info',
                  message: 'Cliente não possui voucher'
                }
              }))
            }
          }else{
            setState(prevState => ({...prevState,
              openModalSelecionarVoucherCredito: true,
            }))
          }
          
        }else{
          if(index === 0){
            setState(prevState => ({...prevState,
              vouchersCreditoSelecionados: [],
              dadosTelaVoucherCredito: {
                totalConta: real(0),
                totalVouchers: real(0),
                diferenca: real(0),
                parcela_index: 0
              },
              vouchersCreditoSelecionadosString: '',
              vouchersCliente: []
            }))
          }
        }

        conta.parcelas[index].habilita = formaPagtoSelecionada.habilita
        conta.parcelas[index].contfin_id = ""
      }else{
        conta.parcelas[index].habilita = ""
        conta.parcelas[index].contfin_id = ""
      }
    }

    
    setState(prevState => ({...prevState, conta, dadosTelaVoucherCredito }))
  }

  const verificaDataParcela = (event, index) => {
    const {conta} = state
        
    for (let i = (index + 1); i < conta.parcelas.length; i++) {
      let count = (i - index)

      let novaData = moment(event.target.value).add(count, 'months').format('YYYY-MM-DD')

      conta.parcelas[i].datavencimento = novaData
      conta.parcelas[i].datavencimento_orig = novaData
    }

    setState(prevState => ({...prevState, conta }))
  }

  const openVouchers = () => {
    setState(prevState => ({...prevState,
      openModalSelecionarVoucherCredito: true,
    }))
  }

  const marcaParcelasContaFin = (event, index) =>{
    
    const {conta, contasFinanceira} = state
        
    const contaFinanceiraSelecionada = contasFinanceira.list.filter(param => param.id === parseInt(event.target.value))[0]
    
    for (let i = index; i < conta.parcelas.length; i++) {
      if(contaFinanceiraSelecionada){
        if(conta.parcelas[i].habilita === contaFinanceiraSelecionada.tipo_conta){
          conta.parcelas[i].contfin_id = event.target.value
        }
      }
    }

    setState(prevState => ({...prevState, conta }))
  }

  const updateFiltroProdutos = (event) =>{
    setState(prevState => ({...prevState,
      filtro_produtos: event.target.value
    }))
  }

  const atualizaConta = () => {
    let { compra, conta } = state

    let total_servicos = somarValores(compra.servicos.map(value => {return parseFloat(value.valor_unit.replaceAll(".", "").replace(",", ".")) * parseInt(value.quantidade)}))
    let total_descontos = somarValores(compra.servicos.map(value => {return parseFloat(value.desconto.replaceAll(".", "").replace(",", ".")) * parseInt(value.quantidade)}))

    setState(prevState => ({...prevState,
      conta: {
        id: conta.id,
        valor_total: real(total_servicos),
        valor_total_minimo: 0,
        valor_total_maximo: 0,
        qtd_parcelas: 1,
        parcelas: [
          {
            id: conta.parcelas[0].id,
            baixado: "",
            multajuros: real(total_descontos),
            datavencimento: conta.parcelas[0].datavencimento,
            datavencimento_orig: conta.parcelas[0].datavencimento_orig,
            valorparcela: real(total_servicos),
            formapagto_id: conta.parcelas[0].formapagto_id,
            contfin_id: conta.parcelas[0].contfin_id,
            documento: conta.parcelas[0].documento,
            habilita: conta.parcelas[0].habilita,
            num: 1,
            parcela_editada: false,
            index: 0,
            trava: false,
            vouchersCreditoSelecionados: [],
            vouchersCreditoSelecionadosString: ''
          }
        ]
      }
    }))
  }

  const selecionarVoucherCredito = async (row) => {
    let {conta, dadosTelaVoucherCredito, vouchersCreditoCliente} = state

    let vouchersCreditoSelecionados = conta.parcelas[dadosTelaVoucherCredito.parcela_index].vouchersCreditoSelecionados
      
    let index = vouchersCreditoSelecionados.findIndex(param => param.voucher_id === row.id)
        
    let valor_total = parseFloat(dadosTelaVoucherCredito.totalConta.replaceAll(".", "").replace(",", "."))
    let totalVouchers = parseFloat(dadosTelaVoucherCredito.totalVouchers.replaceAll(".", "").replace(",", "."))
    let diferenca = parseFloat(dadosTelaVoucherCredito.diferenca.replaceAll(".", "").replace(",", "."))
    
    let index_voucher = await vouchersCreditoCliente.findIndex(param => param.id === row.id)
    
    if(index === -1){
      let valor_a_ultilizar = 0

      if(row.novo_saldo > diferenca){
        totalVouchers += diferenca
        valor_a_ultilizar = diferenca
        vouchersCreditoCliente[index_voucher].novo_saldo = row.novo_saldo - diferenca
        vouchersCreditoCliente[index_voucher].novo_saldo_form = 'R$ ' + real(vouchersCreditoCliente[index_voucher].novo_saldo)
      }else{
        totalVouchers += row.novo_saldo
        valor_a_ultilizar = row.novo_saldo
        vouchersCreditoCliente[index_voucher].novo_saldo = 0
        vouchersCreditoCliente[index_voucher].novo_saldo_form = 'R$ ' + real(0)
      }

      vouchersCreditoSelecionados.push({
        voucher_id: row.id,
        numero: row.numero,
        saldo: row.saldo,
        valor_a_ultilizar
      })

      dadosTelaVoucherCredito.diferenca = real(valor_total - totalVouchers)
      dadosTelaVoucherCredito.totalVouchers = real(totalVouchers)
    }else{     

      vouchersCreditoCliente[index_voucher].novo_saldo = vouchersCreditoCliente[index_voucher].novo_saldo + vouchersCreditoSelecionados[index].valor_a_ultilizar
      vouchersCreditoCliente[index_voucher].novo_saldo_form = "R$ " + real(vouchersCreditoCliente[index_voucher].novo_saldo)
      
      dadosTelaVoucherCredito.totalVouchers = real(parseFloat(dadosTelaVoucherCredito.totalVouchers.replace('R$','').replaceAll(".", "").replace(",", ".")) - vouchersCreditoSelecionados[index].valor_a_ultilizar)
      dadosTelaVoucherCredito.diferenca = real(valor_total - parseFloat(dadosTelaVoucherCredito.totalVouchers.replace('R$','').replaceAll(".", "").replace(",", ".")))
      
      conta.parcelas[dadosTelaVoucherCredito.parcela_index].vouchersCreditoSelecionados.splice(index,1)
      vouchersCreditoSelecionados.splice(index,1)
    }

    let vouchersCreditoSelecionadosString = ''

    for (let i = 0; i < vouchersCreditoSelecionados.length; i++) {
      if((i + 1) === vouchersCreditoSelecionados.length){
        vouchersCreditoSelecionadosString += `${vouchersCreditoSelecionados[i].numero}`
      }else{
        vouchersCreditoSelecionadosString += `${vouchersCreditoSelecionados[i].numero} - `
      }
      
    }

    conta.parcelas[dadosTelaVoucherCredito.parcela_index].vouchersCreditoSelecionados = vouchersCreditoSelecionados
    conta.parcelas[dadosTelaVoucherCredito.parcela_index].vouchersCreditoSelecionadosString = vouchersCreditoSelecionadosString 

    setState(prevState => ({...prevState,
      dadosTelaVoucherCredito,
      vouchersCreditoCliente,
      vouchersCreditoSelecionados,
      conta
    }))
  }

  const finalizarSelecaoVoucher = () => {
    let {dadosTelaVoucherCredito, conta} = state

    let diferenca = parseFloat(dadosTelaVoucherCredito.diferenca.replaceAll(".", "").replace(",", "."))
    let totalVouchers = parseFloat(dadosTelaVoucherCredito.totalVouchers.replaceAll(".", "").replace(",", "."))

    if(totalVouchers > 0){
      if(diferenca > 0){
        let formas_vouchers = conta.parcelas.filter(param => parseInt(param.formapagto_id) === 9)

        if(conta.parcelas.length === formas_vouchers.length){
          conta.parcelas[dadosTelaVoucherCredito.parcela_index].valorparcela = dadosTelaVoucherCredito.totalVouchers
    
          conta.qtd_parcelas = 2
    
          conta.parcelas.push({
            id: '',
            baixado: false,
            datavencimento: moment(new Date()).format('YYYY-MM-DD'),
            datavencimento_orig: moment(new Date()).format('YYYY-MM-DD'),
            valorparcela: real(diferenca),
            multajuros: real(0),
            formapagto_id: "",
            contfin_id: "",
            documento: "",
            habilita: "",
            num: 2,
            parcela_editada: false,
            index: 1,
            trava: false,
            vouchersCreditoSelecionados: [],
            vouchersCreditoSelecionadosString: ''
          })
        }else{
          conta.parcelas[dadosTelaVoucherCredito.parcela_index].valorparcela = real(totalVouchers)

          for (let i = 0; i < conta.parcelas.length; i++) {
            if(parseInt(conta.parcelas[i].formapagto_id) !== 9){
              let valorparcela = parseFloat(conta.parcelas[i].valorparcela.replaceAll(".", "").replace(",", "."))
              let diferenca_div = diferenca / (conta.parcelas.length - formas_vouchers.length)

              conta.parcelas[i].valorparcela = real(parseFloat((valorparcela + diferenca_div).toFixed(2)))
            }
          }
        }

        setState(prevState => ({...prevState,
          dadosTelaVoucherCredito: {
            totalConta: real(totalVouchers),
            totalVouchers: real(totalVouchers),
            diferenca: real(0),
            parcela_index: 0
          }
        }))
      }
    }else{
      conta.parcelas[0].formapagto_id = ''
    }
  
    setState(prevState => ({...prevState,
      openModalSelecionarVoucherCredito: false,
      conta
    }))
  }

  const validateForm = () => {
    const { compra, cliente } = state

    if(compra.unidade_id === '') return false
    if(cliente.pessoa_id === '') return false
    if(compra.data_pedido === '') return false
    
    return true
  }

  const salvar = async (baixar) => {

    if (!validateForm()) {
      setState(prevState => ({...prevState,
        alerta: {
          open: true,
          severity: 'warning',
          message: 'Preencha os campos obrigatorios (*).'
        }
      }))
      return
    }

    const {compra, conta, cliente} = state
    
    if(compra.servicos.length === 0){
      setState(prevState => ({...prevState,
        alerta: {
          open: true,
          severity: 'warning',
          message: 'Por favor informar pelo menos um item.'
        }
      }))
      return
    }
    
    for (const parcela of conta.parcelas) {
      if(parcela.datavencimento === ""){
        setState(prevState => ({...prevState,
          alerta: {
            open: true,
            severity: 'warning',
            message: `Informar a data de vemcimento da parcela ${parcela.index}!`
          }
        }))
        return
      }

      if(parcela.valorparcela === ""){
        setState(prevState => ({...prevState,
          alerta: {
            open: true,
            severity: 'warning',
            message: `Informar o valor da parcela ${parcela.index}!`
          }
        }))
        return
      }

      if(parcela.formapagto_id === ""){
        setState(prevState => ({...prevState,
          alerta: {
            open: true,
            severity: 'warning',
            message: `Informar a forma de pagamento da parcela ${parcela.index}!`
          }
        }))
        return
      }

      if(parcela.contfin_id === "" && parseInt(parcela.formapagto_id) !== 9){
        setState(prevState => ({...prevState,
          alerta: {
            open: true,
            severity: 'warning',
            message: `Informar a conta da parcela ${parcela.index + 1}!`
          }
        }))
        return
      }

      let valor_parcela = parseFloat(parcela.valorparcela.replaceAll(".", "").replace(",", "."))
      let valor_desconto = parseFloat(parcela.multajuros.replaceAll(".", "").replace(",", "."))

      // if(valor_desconto > 0){
      //   setState(prevState => ({...prevState,
      //     alerta: {
      //       open: true,
      //       severity: 'warning',
      //       message: 'Não é possivel lançar um acrescimo na parcela.'
      //     },
      //     loading: false
      //   })
      //   return
      // }

      if(valor_desconto  > valor_parcela){
        setState(prevState => ({...prevState,
          alerta: {
            open: true,
            severity: 'warning',
            message: 'O valor do desconto não pode ser superior ao valor da parcela.'
          },
          loading: false
        }))
        return
      }
    }

    setState(prevState => ({...prevState, loading: true }))

    let dados = {
      id: compra.id,
      data_pedido: compra.data_pedido,
      pessoa_id: cliente.pessoa_id,
      observacoes: compra.observacoes,
      unidade_id: compra.unidade_id,
      produtos: compra.servicos.map(servico => {
        return {
          item_id: servico.item_id,
          produto_id: parseInt(servico.produto_id),
          quantidade: parseInt(servico.quantidade),
          valor_unit: parseFloat(servico.valor_unit.replaceAll(".", "").replace(",", ".")),
          valor_total_pedido: parseFloat(servico.valor_total_pedido.replaceAll(".", "").replace(",", ".")),
          desconto: parseFloat(servico.desconto.replaceAll(".", "").replace(",", ".")),
          data_execucao:servico.data_execucao,
        }
      }),
      conta: {
        id: conta.id,
        valortotal: somarValores(conta.parcelas.map(parcela => {return parseFloat(parcela.valorparcela.replaceAll(".", "").replace(",", "."))})),
        parcelas: conta.parcelas.map(parcela => {
          return {
            id: parcela.id,
            datavencimento: parcela.datavencimento,
            valorparcela: parseFloat(parcela.valorparcela.replaceAll(".", "").replace(",", ".")),
            num: parcela.index + 1,
            contfin_id: parseInt(parcela.contfin_id),
            formapagto_id: parseInt(parcela.formapagto_id),
            multajuros: parseFloat(parcela.multajuros.replaceAll(".", "").replace(",", ".")),
            vouchers_credito: parcela.vouchersCreditoSelecionados,
            baixado: baixar
          }
        })
      }
    }
    
    if(state.logado.caixa){
      const ultimo = JSON.parse(localStorage.getItem('ultimoCaixa'))
      
      dados.conta.caixa = ultimo.id
    }

    for (let i = 0; i < dados.conta.parcelas.length; i++) {
      if(dados.conta.parcelas[i].formapagto_id === 9){
        delete dados.conta.parcelas[i].contfin_id
      }
      if(dados.conta.parcelas[i].vouchers_credito.length === 0){
        delete dados.conta.parcelas[i].vouchers_credito
      }
    }

    try {

      if(compra.id === ''){
        await axios.post(`${window._env_.REACT_APP_API_URL}/compras`, dados, getToken())
      }else{
        await axios.put(`${window._env_.REACT_APP_API_URL}/compras/${dados.id}`, dados, getToken())
      }

      setState(prevState => ({...prevState,
        alerta: {
          open: true,
          severity: 'success',
          message: 'Cadastro realizado com sucesso.'
        },
        loading: false
      }))

      backPage()

    } catch (error) {
      console.log(error)
      setState(prevState => ({...prevState,
        alerta: {
          open: true,
          severity: 'error',
          message: error.response ? error.response.data.message : 'Erro Interno'
        },
        loading: false
      }))
    }
  }

  const voltar = () => {
    const self = this
    navigate("/compras");
  }

  const consultarGrupos = async (unidade_id) => {
    try {

      const dados = {
        unidadesnegocio: [unidade_id]
      }

      const { data: grupoProdutos } = await axios.post(`${window._env_.REACT_APP_API_URL}/grupoProdutos/list`, dados, getToken())

      setState(prevState => ({...prevState,
        grupoProdutos: {
          list: grupoProdutos.filter(u => u.ativo === true).sort((a, b) => (a.descricao > b.descricao) ? 1 : (b.descricao > a.descricao) ? -1 : 0)
        }
      }))

    } catch (error) {
      console.log(error)
      setState(prevState => ({...prevState,
        alerta: {
          open: true,
          severity: 'error',
          message: error.response ? error.response.data.message : 'Erro Interno'
        }
      }))
    }
  }

  const consultarSubGrupos = async (unidade_id) => {
    try {

      const dados = {
        unidadesnegocio: [unidade_id]
      }

      const { data: subGrupoProdutosAux } = await axios.post(`${window._env_.REACT_APP_API_URL}/subGrupoProdutos/list`, dados, getToken())

      setState(prevState => ({...prevState,
        subGrupoProdutos: {
          list: subGrupoProdutosAux.filter(u => u.ativo === true).sort((a, b) => (a.descricao > b.descricao) ? 1 : (b.descricao > a.descricao) ? -1 : 0)
        }
      }))

    } catch (error) {
      console.log(error)
      setState(prevState => ({...prevState,
        alerta: {
          open: true,
          severity: 'error',
          message: error.response ? error.response.data.message : 'Erro Interno'
        }
      }))
    }
  }

  useEffect(() => {
    const fetchData = async () => {
      // const helpPath = require("./../../help/compras/Cadastro.md")

      // fetch(helpPath)
      //   .then(response => {
      //     return response.text()
      //   })
      //   .then(text => {
      //     setState(prevState => ({...prevState,
      //       markdown: text
      //     }))
      //   })

      let compra = {
        id: '',
        data_pedido: '',
        observacoes: '',
        unidade_id: '',
        numero: '',
        servicos: []
      }

      setState(prevState => ({...prevState,
        compra,
        servico: {
          item_id: '',
          grupo_id: '',
          sub_grupo_id: '',
          produto_id: '',
          quantidade: 1,
          data_execucao: '',
          documento: '',
          valor_unit: real(0),
          valor_total_pedido: real(0),
          desconto: real(0),
          nome: '',
          index: '',
          tipo: ''
        },
        servico_selecionado: {
          id: '',
          nome: '',
          unidademedida: '',
          tipo: '',
          valor: real(0),
          valor_total_minimo: real(0),
          valor_total_maximo: real(0)
        },
        conta: {
          id: '',
          valor_total: real(0),
          valor_total_minimo: 0,
          valor_total_maximo: 0,
          qtd_parcelas: 1,
          parcelas: [
            {
              id: '',
              baixado: "",
              multajuros: real(0),
              datavencimento: moment().format('YYYY-MM-DD'),
              datavencimento_orig: moment().format('YYYY-MM-DD'),
              valorparcela: real(0),
              formapagto_id: "",
              contfin_id: "",
              documento: "",
              habilita: "",
              num: 1,
              parcela_editada: false,
              index: 0,
              trava: false,
              vouchersCreditoSelecionados: [],
              vouchersCreditoSelecionadosString: ''
            }
          ]
        },
      }))

      const perfil = JSON.parse(localStorage.getItem('perfil'))

      let permissoes = perfil.permissoes.filter(param => param.tela.modulo.slug === 'compra-servico-produto')[0]

      setState(prevState => ({...prevState,
        permissoes
      }))

      let permissoesContasPR = perfil.permissoes.filter(param => param.tela.modulo.slug === 'contas-pagar-receber')[0]
      let permissoesCaixa = perfil.permissoes.filter(param => param.tela.modulo.slug === 'caixa')[0]

      let perfilLogado = ''

      if (permissoesCaixa) {
        perfilLogado = 'caixa'
        setState(prevState => ({...prevState,
          permissoesCaixa,
          perfilLogado: 'caixa'
        }))
      }

      if (permissoesContasPR) {
        perfilLogado = 'contas-pagar-receber'
        setState(prevState => ({...prevState,
          permissoesContasPR,
          perfilLogado: 'contas-pagar-receber'
        }))
      }

      const caixa = JSON.parse(localStorage.getItem('caixa'))
      const ultimoCaixa = JSON.parse(localStorage.getItem('ultimoCaixa'))

      if(perfilLogado === 'contas-pagar-receber'){
        if(ultimoCaixa && !ultimoCaixa.fechado){
          setState(prevState => ({...prevState, logado: { caixa } }))
        }else{
          setState(prevState => ({...prevState,
            logado: {
              caixa: false
            }
          }))
        }

      }else{
        setState(prevState => ({...prevState, logado: { caixa } }))
      }

      if(ultimoCaixa && ultimoCaixa['status_atual.status'] === 'ABERTO'){
        setState(prevState => ({...prevState,
          permissoesCaixa,
          perfilLogado: 'caixa'
        }))
      }

      setState(prevState => ({...prevState,
        ultimoCaixa: ultimoCaixa || {}
      }))

      try {
        const acesso_atual = JSON.parse(localStorage.getItem('acesso_atual'))

        const dados = {
          unidadesnegocio: acesso_atual.map(acesso => {
            return acesso.id
          })
        }

        const { data: unidades } = await axios.post(`${window._env_.REACT_APP_API_URL}/unidades/list/grupoAll`, dados, getToken())

        setState(prevState => ({...prevState,
          unidades: {
            list: unidades.filter(param => (param.tipo === 'UNIDADE' || param.tipo === 'MANTENEDOR')).sort((a, b) => (a.id > b.id) ? 1 : (b.id > a.id) ? -1 : 0).map(unidade => {
              return {
                id: unidade.id,
                descricao: `${unidade.numero} - ${unidade.descricao}`,
                tipo: unidade.tipo
              }
            })
          }
        }))

        if(unidades.length === 1){
          compra.unidade_id = unidades[0].id
          await consultarGrupos(unidades[0].id)
          await consultarSubGrupos(unidades[0].id)
          setState(prevState => ({...prevState, compra, travarUnidade: true}))
        }
      } catch (error) {
        console.log(error)
        setState(prevState => ({...prevState,
          alerta: {
            open: true,
            severity: 'error',
            message: error.response ? error.response.data.message : 'Erro Interno'
          }
        }))
      }

      if(compraId){
        try {
          const { data: compra } = await axios.get(`${window._env_.REACT_APP_API_URL}/compras/${compraId}`, getToken())
          

          let conta = compra.conta

          setState(prevState => ({...prevState,
            compra: {
              id: compra.id,
              data_pedido: moment(compra.data_pedido).format("YYYY-MM-DD"),
              observacoes: compra.observacao,
              numero: compra.numero,
              unidade_id: compra.unidade_id,
              servicos: compra.itens_compra.map((value, key) => {
                let valor_unit = value.valor_produto
                return {
                  item_id: value.id,
                  produto_id: value.produto_id,
                  quantidade: value.quantidade,
                  data_execucao: value.data_execucao,
                  valor_unit: real(valor_unit * -1),
                  valor_total_pedido: real(((valor_unit + value.desconto) * value.quantidade) * -1),
                  desconto: real(value.desconto * -1),
                  nome: value.produto.descricao,
                  tipo: value.produto.tipo,
                  grupo_id: value.produto.subgrupoproduto.grupoproduto.id,
                  grupo: value.produto.subgrupoproduto.grupoproduto.descricao,
                  sub_grupo_id: value.produto.subgrupoproduto.id,
                  sub_grupo: value.produto.subgrupoproduto.descricao,
                  index: key,
                }
              })
            },
            cliente: {
              pessoa_id: compra.pessoa.id,
              nome: compra.pessoa.tipo === 'FISICA' ? compra.pessoa.fisica.nome : (compra.pessoa.juridica.nomefantasia + ' - ' + compra.pessoa.juridica.razaosocial)
            },
            conta: {
              id: conta.id,
              valor_total: real(conta.valortotal * -1),
              valor_total_minimo: real(0),
              valor_total_maximo:real(0),
              qtd_parcelas: conta.numparcela,
              parcelas: conta.parcelas.map((parcela, key) => {
                return {
                  id: parcela.id,
                  baixado: parcela.baixado,
                  multajuros: real(parcela.multajuros * -1),
                  datavencimento: parcela.datavencimento,
                  datavencimento_orig: parcela.datavencimento,
                  valorparcela: real(parcela.valorparcela * -1),
                  formapagto_id: parcela.valores_pago[0].formapagto_id,
                  contfin_id: parcela.valores_pago[0].contfin_id,
                  documento: parcela.documento,
                  habilita: parcela.valores_pago[0].conta_financeira.tipo_conta,
                  num: parcela.num,
                  parcela_editada: false,
                  index: key,
                  trava: false,
                  vouchersCreditoSelecionados: [],
                  vouchersCreditoSelecionadosString: ''
                }
              })
            }
          }))
        } catch (error) {
          console.log(error)
          setState(prevState => ({...prevState,
            alerta: {
              open: true,
              severity: 'error',
              message: error.response ? error.response.data.message : 'Erro Interno'
            }
          }))

          backPage()
          return false
        }
      }
      
      await consultarFormaPagto()
      await consultarContasFinanceira()

      setState(prevState => ({...prevState,
        loading: false
      }))
    };
    
    fetchData();
  }, [compraId]);

  const { compra, pessoas, cliente, servico, updateServico, produtos, servico_selecionado, conta } = state

  return (
    <React.Fragment>
      {!state.loading &&
        <div className="app-menu-closed" id="app">
          <Main openModalAjuda={() => {setState(prevState => ({...prevState,openModalAjuda: true}))}}>
            <div>
              <Grid
                container
                spacing={1}
                direction="row"
                className="borderBottom"
              >
                <Grid item md={12}>
                  <h1 className="titulo">Compra de Produtos/Serviços</h1>
                </Grid>
              </Grid>
              <FormCompra 
                dados={compra}
                updateField={e => updateField(e)}
                pessoas={pessoas.list}
                cliente={cliente}
                updateFieldPessoaFiltro={(event, value) => updateFieldPessoaFiltro(event, value)}
                updateFiltroPessoa={(e) => updateFiltroPessoa(e)}
                produtos={produtos.list}
                servico={servico}
                updateServico={updateServico}
                addServico={() => addServico()}
                updateFieldServicoAutoComplete={(e, value) => updateFieldServicoAutoComplete(e, value)}
                servico_selecionado={servico_selecionado}
                updateFieldServico={(e) => updateFieldServico(e)}
                editarItemServico={(e) => editarItemServico(e)}
                removerItemServico={(e) => removerItemServico(e)}
                conta={conta}
                updateFieldConta={(e) => updateFieldConta(e)}
                mudarParcelas={e => mudarParcelas(e)}
                recalcularParcela={(e, value) => recalcularParcela(e, value)}
                updateFieldParcela={(e, index) => updateFieldParcela(e, index)}
                formasPagamento={state.formasPagamento.list}
                contasFinanceira={state.contasFinanceira.list}
                verificaDataParcela={(e, index) => verificaDataParcela(e, index)}
                openVouchers={() => openVouchers()}
                marcaParcelasContaFin={(e, index) => marcaParcelasContaFin(e, index)}
                logado={state.logado}
                filtro_produtos={state.filtro_produtos}
                updateFiltroProdutos={(e) => updateFiltroProdutos(e)}
                travarUnidade={state.travarUnidade}
                unidades={state.unidades.list}
                grupoProdutos={state.grupoProdutos.list}
                subGrupoProdutos={state.subGrupoProdutos.list}
              />
              <Grid container direction="row" spacing={1} className="mg_top_10">
                <Grid item md={2} xs={12} sm={4}>
                  <Button 
                    fullWidth 
                    variant="contained"
                    size="small" 
                    color="secondary"
                    startIcon={<ArrowBackIcon />}
                    onClick={e => voltar()}
                  >
                    Voltar
                  </Button>
                </Grid>
                <Grid item md={6} xs={false} sm={4}></Grid>
                <Grid item md={2} xs={12} sm={4}>
                  <Button 
                    fullWidth 
                    variant="contained"
                    size="small" 
                    startIcon={<SaveIcon />} 
                    onClick={e => salvar(true)}
                    color='success'
                  >Salvar e Baixar</Button>
                </Grid>
                <Grid item md={2} xs={12} sm={4}>
                  <Button 
                    fullWidth 
                    color="primary"
                    variant="contained"
                    size="small" 
                    startIcon={<SaveIcon />} 
                    onClick={e => salvar(false)}>Salvar</Button>
                </Grid>
              </Grid>
            </div>
          </Main>
          <Nav/>
          <Alert 
            open={state.alerta.open}
            handleClose={e => handleCloseAlerta()} 
            severity={state.alerta.severity}
            message={state.alerta.message} 
          />
        </div>
      }
      <Loading
        open={state.loading}
        message={state.mensagemLoading} 
      />
      <ModalAjuda 
        open={state.openModalAjuda}
        tela={"Compra de Produtos/Serviços"}
        handleClose={() => {setState(prevState => ({...prevState,openModalAjuda: false}))}}
        markdown={state.markdown}
      />
    </React.Fragment>
  )
}

export default CadastroCompras